// UI for front
// console.log('Front!!');

document.querySelectorAll('[data-wbn-confirm]').forEach(function (element) {
	element.addEventListener('click', () => {
		return confirm(element.dataset.wbnConfirm);
	});
});

// najdeme všechny podřízené selectboxy
document.querySelectorAll('select[data-depends]').forEach(function (dependent) {
	let main = dependent.form[dependent.dataset.depends]; // hlavní <select>

	// a když uživatel změní vybranou položku…
	main.addEventListener('change', function () {
		let url = dependent.dataset.url; // URL pro našeptávání
		// ...uděláme požadavek na Endpoint presenter a pošleme klíč
		fetch(url.replace(encodeURIComponent('#'), encodeURIComponent(main.value)))
			.then(response => response.json())
			// a nahrajeme do podřízeného nová data
			.then(data => updateSelectbox(dependent, data));
	});
});

// vloží nové <options> do <select>
function updateSelectbox(select, items) {
	select.innerHTML = ''; // odstraníme vše
	for (let id in items) { // vložime nové
		let el = document.createElement('option');
		el.setAttribute('value', id);
		el.innerText = items[id];
		select.appendChild(el);
	}
}
